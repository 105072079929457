@import '../../variables';

.MemberPWReminder {
  max-width: 20em;
  margin: 2em auto;
  background: #ddd;
  padding: 1em;
  text-align: center;

  &>span {
    font-size: 70%;
  }

  .SupportLink {
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }

  .InputWrapper {
    border: none;
    text-transform: uppercase;
    border-radius: 0.3em;
    margin-bottom: .5em;
    padding: .5em;
    line-height: 1;
    background-color: #ffffff;
    color: #98999d;

    input {
      width: 100%;
      margin: 0 .3em;
      padding: 0 .3em;
      outline: none;
      border: none;
      font-family: $font-family-metropolis;
    }
  }

  .SignInButton {
    border: none;
    text-transform: uppercase;
    border-radius: 0.3em;
    margin-bottom: .5em;
    padding: .5em;
    color: #191919;
    background-color: #b89e51;
    cursor: pointer;
    font: inherit;
    width: 100%;
  }

  form {
    margin-top: .8em;
  }
}

.ModalPortalContent .MemberPWReminder {
  margin: 0;
  background: unset;
  line-height: 1;
  max-width: unset;
  color: $white;
}