@import '../../variables';

.UserActions {
  display: none;
  cursor: pointer; 
  color: $white; 
  text-transform: uppercase; 
  font-size: 1.1em; 
  font-weight: 500;
  text-align: center;
  white-space: nowrap;

  @media (min-width: $breakpoint-tablet) { 
    display: flex;
  }

  .UserAction {
    text-transform: uppercase;

    &:hover {
      color: $primary;
    } 
  } 
} 
