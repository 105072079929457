@import '../../../../variables';

.JustAddedComponent {
  .SliderType .SlidePlaceholder {
    height: 40vw;
  }

  .Filters {
    margin: 10px auto;
    display: flex;
    border-radius: 7px;
    padding: 5px 50px;
    background-color: $brownColor;
    color: $white;
    text-transform: uppercase;
    width: 95%;
    font-family: $font-family-metropolis;

    .Label {
      margin-right: 30px;
      font-weight: bold;
      color: $contrast;
    }

    .Filters-inner {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .FilterItem {
        margin-right: 30px;
        opacity: 0.8;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }

    .SeeMore {
      margin-left: 30px;
      font-style: italic;
      text-transform: none;
      cursor: pointer;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .HomePageSlider {

      .FullPlayer,
      .SlidePlaceholder {
        height: calc(100vw * (3 / 5));
      }

      .SliderContent {
        max-height: unset;
      }

      img {
        object-fit: cover;
        aspect-ratio: 5 / 3;
        object-position: left center;
      }
    }
  }
}